html, body, #root, .App {
  height: 100%;
}

.App {
  display: flex;
  flex-direction: column;
}

.App-header {
  flex-grow: 0;
  border-bottom: 1px solid #cacaca;
  background-color: rgba(0, 0, 0, 0.11);
}

.App-header h1 {
  font-size: 17px;
  margin: 7px 15px;
  font-weight: normal;
}

.App-header h1 > strong {
  font-weight: bold;
}

.App-content {
  flex-grow: 1;
}

.App-footer {
  flex-grow: 0;
  height: 20px;
  border-top: 1px solid #cacaca;
  background-color: rgba(0, 0, 0, 0.11);
  font-size: 11px;
  text-align: center;
  color: #666;
}

.App-content-columns {
  display: flex;
}

.App-invoice-list {
  flex-grow: 0;
  width: 20%;
  max-width: 300px;
  min-width: 240px;
  background-color: rgba(0, 0, 0, 0.08);
  border-right: 1px solid #cacaca;
}

.App-invoice-form {
  flex-grow: 0;
  width: 50%;
  max-width: 700px;
  min-width: 600px;
  background-color: rgba(0, 0, 0, 0.05);
  border-right: 1px solid #cacaca;
}

.App-invoice-preview {
  flex-grow: 1;
}

.App-invoice-list-items {
  list-style: none;
  margin: 0;
  padding: 0;
}

.App-invoice-list-items > li {
  border-bottom: 1px solid #cacaca;
  margin: 0;
}

.App-invoice-list-items > li > a > small {
  font-size: 11px;
}
.App-invoice-list-items > li > a {
  display: block;
  font-size: 15px;
  text-decoration: none;
  color: #333;
  padding: 7px 15px;
}

.App-invoice-list-items > li > a:hover {
  background-color: #fafafa;
}

.App-invoice-list .Invoice-active > a {
  position: relative;
  background-color: rgba(255, 255, 255, 0.4);
}
.App-invoice-list .Invoice-active > a::after {
  position: absolute;
  content: " ";
  height: 60px;
  background-color: rgba(255, 255, 255, 0.7);
  width: 1px;
  top: 0;
  right: -1px;
}
.App-invoice-list .Invoice-active > a:hover {
  background-color: #f5f5f5;
}

@media print {
  .App-header { display: none; }
  .App-footer { display: none; }
  .App-invoice-list { display: none; }
  .App-invoice-form { display: none; }
}