html, body {
  height: 100%;
  padding: 0;
  margin: 0;
}

body {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 10px;
  color: #000;
  -webkit-font-smoothing: antialiased;
}